<template>
  <v-container>
    <v-row>
      <v-col :cols="orderStatus === 'apply' ? 8 : 12">
        <div class="d-flex align-center">
          <page-title title="訂單明細" />
          <span v-if="false && showOrderAdjustmentTip" class="px-1 red--text">(*訂單經調整,請確認品項數量)</span>
        </div>
      </v-col>
      <v-col cols="4" class="d-flex justify-end align-center" v-if="orderStatus === 'apply'">
        <v-btn
          @click="pickingExportOrderContent"
          color="secondary"
          small
          class="mx-2"
        >
          匯出揀貨單
        </v-btn>
      </v-col>
    </v-row>
    <customForm
      :slotComponents="slotComponents"
      :handlerSubmit="updateApi"
      :value="readData"
    >
      <template v-slot:action="{ defaultKey }">
        <OrderButton
          v-if="!showOrderAdjustmentTip"
          :defaultKey="defaultKey"
          :orderStatus="orderStatus"
        ></OrderButton>
      </template>
    </customForm>
    <div class="pa-4 text-center" v-if="false">
      <v-btn v-if="showOrderAdjustmentTip" color="primary" outlined rounded @click="openOrderAdjustmentCheckDialog = true">確認訂單調整</v-btn>
    </div>
    <v-dialog v-model="openGoReviewOrderAdjustmentCheckDialog" persistent max-width="290" class="linerp-layout">
      <v-card>
        <v-card-title>
          此筆訂單經過修改<br>
          查看調整後訂單？
        </v-card-title>
        <v-card-actions class="pb-3 linerp-layout">
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="openGoReviewOrderAdjustmentCheckDialog = false">
            取消
          </v-btn>
          <v-btn color="primary" @click="handleClickCheckAdjustOrder()">
            確認
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <order-adjustment-check
      :openOrderAdjustmentCheckDialog="openOrderAdjustmentCheckDialog"
      :orderData="readData || {}"
      :providerId="providerId"
      @updateOrderAdjustmentCheckDialogSwitch="(isOpen) => openOrderAdjustmentCheckDialog = isOpen"
      @updateOrderAdjustmentSuccess="readApi"
    />
  </v-container>
</template>

<script lang="babel" type="text/babel">
import customForm from "@/components/form/custom/customForm.vue";
import OrderInfo from '@/modules/provider/views/orderDetail/OrderInfo.vue'
import OrderButton from '@/modules/provider/views/orderDetail/OrderButton.vue'
import OrderAdjustmentCheck from '@/modules/provider/views/orderDetail/OrderAdjustmentCheck.vue'
import qs from 'qs'
import storage from 'libs/storage/localStorage.js'

export default {
  components: {
    customForm,
    OrderButton,
    OrderAdjustmentCheck
  },

  data: () => ({
    readData : null,
    orderStatus : null,
    openOrderAdjustmentCheckDialog: false,
    openGoReviewOrderAdjustmentCheckDialog: false
  }),
  created() {
    this.beforeFormInit();
  },
  computed: {
    providerId() {
      //return this.$store.getters[`member/providerId`]
      return this.$route.params.providerId;
    },
    memberId() {
      return this.$store.getters[`token/memberId`];
    },

    slotComponents() {
      return [
        {
          values: ["*"],
          excepts: ["items"],
          component: () =>
            import("@/modules/provider/views/orderDetail/orderIntro.vue"),
        },
        {
          values: ["*"],
          component: () =>
            import("@/modules/provider/views/orderDetail/OrderItems2.vue"),
        },
        {
          values: ["received_record", "status", "arrival_at"],
          component: () =>
            import("@/modules/provider/views/orderDetail/receiptLog.vue"),
        },
        {
          values: ["*"],
          component: () =>
            import("@/modules/provider/views/orderDetail/BackRecords.vue"),
        },
      ];
    },
    canCheckAdjustOrder() {
      return ['created', 'apply'].includes(this.orderStatus)
    },
    showOrderAdjustmentTip(){
      return this.readData && this.readData.orderAdjustment && this.readData.orderAdjustment.length > 0 && this.readData.orderAdjustment[this.readData.orderAdjustment.length-1].status === ''
    }
  },
  methods: {
    async pickingExportOrderContent() {
      this.$root.gaLogEvent('供應商_點擊_匯出揀貨單')
      console.log("this.readData", this.readData);
      const results = this.readData;

      let data = {
        address: results.store_info.address,
        comment: results.comment,
        created_at: results.created_at,
        items: results.items,
        order_no: results.order_no,
        phone: results.store_info.phone,
        receive_name: results.store_info.contact,
        store_name: results.store_name,
        delivery_fee: results.delivery_fee,
      };

      try {
        this.$helper.openExternalBrowser(
          "",
          "/linerp/provider/pickingList/order/download",
          {
            data: encodeURIComponent(JSON.stringify(data)),
            content: 1,
            complete: 1,
          }
        );
      } catch (error) {
        console.error(error);
        return;
      }
    },
    async beforeFormInit() {
      this.$store.dispatch("loading/active");

      const storageInstance = storage();

      if (
        storageInstance.get("chooseProviderId") !==
        this.$route.params.providerId
      ) {
        storageInstance.set("chooseProviderId", this.$route.params.providerId);
        this.$router.go(0);
      }

      try {
        await Promise.all([this.readApi()]);
      } catch (err) {
        console.error(err);
        this.$snotify.error("讀取錯誤");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async readApi() {
      const res = await this.$api.collection.providerApi.getOrderDetail(this.providerId,this.$route.params.orderId)
      const res2 = await this.$api.collection.favoriteLogisticApi.get(this.providerId)
      const res3 = await this.$api.collection.receivingDateApi.index(res.store_info.id)
      const res4 = await this.$api.collection.providerApi.getOrderAdjustment(this.providerId, this.$route.params.orderId)
      if (res2) {
        res.logisticList = res2;
      }
      if (res3) {
        res.receivingDate = res3;
      }
      if (res4) {
        res.orderAdjustment = res4.data || []
        res.items = res.items.map((itemV)=>{
          if(res.orderAdjustment.length > 0 && res.orderAdjustment[res.orderAdjustment.length-1].status === ''){
            res.orderAdjustment[res.orderAdjustment.length-1].items.forEach(adjustmentV => {
              if(adjustmentV.product_id === itemV.product_id){
                itemV.adjustmentOriginCount = adjustmentV.old_count
                itemV.adjustmentModifyCount = adjustmentV.new_count
                itemV.adjustmentOriginTotal = adjustmentV.old_amount
                itemV.adjustmentModifyTotal = adjustmentV.new_amount
              }
            })
          }
          return itemV
        })
      }
      if(!res) {
        this.$snotify.error('讀取錯誤')
      } else {
        this.readData = res 
        this.orderStatus = res.status
      }
      if(false && this.showOrderAdjustmentTip) this.handleClickGoReviewOrderAdjustmentCheck()
    },
    async updateApi(data) {
      this.$store.dispatch("loading/active");
      try {
        // 轉換shippingCount
        const cloneData = this.$eagleLodash.cloneDeep(data);
        if (cloneData.status === 'sended') this.$root.gaLogEvent('供應商_點擊_出貨')
        if (cloneData.status === 'apply') this.$root.gaLogEvent('供應商_點擊_接單')
        if (cloneData.status === 'reject') this.$root.gaLogEvent('供應商_點擊_拒絕接單')
        console.log('cloneData:', cloneData)
        // const total_amount = Number(cloneData.total_amount) || 0
        // const delivery_fee = Number(cloneData.delivery_fee) || 0
        cloneData.amount = Number(cloneData.total_amount) || 0;
        for (let key in cloneData) {
          const matched = key.match(/shipping/);
          if (matched) {
            cloneData[key] = this.$helper.transformShippingCount({
              count: cloneData[key],
              force: true,
            });
          }
        }
        cloneData.createPageDatetime = this.$route.query.createPageDatetime;
        let res = await this.$api.collection.providerApi.updateOrder(
          this.providerId,
          this.$route.params.orderId,
          qs.stringify(cloneData)
        );

        this.readData = res;
        this.orderStatus = res.status;
        this.$snotify.success("已變更狀態");
      } catch (error) {
        console.error(error);
        this.$snotify.error("變更失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    getFormConfig() {
      return formConfig;
    },
    handleClickGoReviewOrderAdjustmentCheck() {
      this.openGoReviewOrderAdjustmentCheckDialog = true
    },
    handleClickCheckAdjustOrder() {
      this.openGoReviewOrderAdjustmentCheckDialog = false
      this.openOrderAdjustmentCheckDialog = true
    }
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
